<template>
  <section class="audit-list">
    <el-table :data="tableData" border style="width: 100%" v-loading="loading">
      <el-table-column  :resizable="false" prop="emotion" label="情感" align="center" width="100">
        <template scope="{ row }">
          <el-tag size="mini" v-if="row.isDanger === 0" effect="dark" style="background-color: #84BFEC;border-color:#84BFEC;height:19px;padding:0 3px;border-radius:2px;">正</el-tag>
          <el-tag size="mini" v-else-if="row.isDanger === 1"  effect="dark" style="background-color: #ED9090;border-color:#ED9090;height:19px;padding:0 3px;border-radius:2px;">负</el-tag>
          <el-tag size="mini" v-else-if="row.isDanger === 2" effect="dark" style="background-color: #EDD990;border-color:#EDD990;height:19px;padding:0 3px;border-radius:2px;">敏</el-tag>
        </template>
      </el-table-column>
      <el-table-column  :resizable="false" label="标题" prop="title" header-align="center" min-width="220px"
                       show-overflow-tooltip>
        <template scope="{ row }">
          <!-- viewType 为2的时候标题和摘要都显示 -->
          <span v-html="row.title">{{row.title}}</span>
        </template>
      </el-table-column>
      <el-table-column  :resizable="false" label="来源" align="center" show-overflow-tooltip>
        <template scope="{ row }">
          <span v-if="row.customeSource === null || row.customeSource === ''">{{ row.sourceName }}</span>
          <span v-else>{{ row.customeSource }}</span>
        </template>
      </el-table-column>
      <el-table-column  :resizable="false" prop="publishDate" label="生成时间" align="center" width="160" sortable>
        <template scope="{ row }">
          {{dateFormat(row.reviewDate)}}
        </template>
      </el-table-column>
      <el-table-column  :resizable="false" align="center" width="120" label="操作">
        <template scope="{ row }">
         <el-button v-if="row.isTrack === 2" type="text" @click="adopt(row)" style="color: #3472c7">通过</el-button>
         <el-button v-if="row.isTrack === 2" type="text" @click="refuse(row)"  style="color:#AA1111;">拒绝</el-button>
          <span v-if="row.isTrack === 3">已拒绝</span>
        </template>
      </el-table-column>
    </el-table>
    <section class="list-bottom">
      <span class="count">共{{count}}条记录</span>
      <el-pagination :current-page="currentPage"
                     :page-sizes="[10, 20, 30, 50]"
                     :page-size="limit"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="count"
                     @size-change="sizeChange"
                     @current-change="currentChange">
      </el-pagination>
    </section>
  </section>
</template>
<script>
import { getApplyList, setUntrack, setTrack, refuseTrack } from '@/api/dataTrack'
export default {
  methods: {
    dateFormat (date) {
	    date = date.replace(/-/g,"/").substr(0,date.indexOf('.'))
      var t = new Date(date)
      var year=t.getFullYear(),
     　　 month=t.getMonth()+1,
     　　 day=t.getDate(),
    　　  hour=t.getHours(),
    　　  min=t.getMinutes(),
    　　  sec=t.getSeconds()
　　  var newTime = year + '-' +
    　　  (month < 10 ? '0'+ month : month) + '-' +
   　　   (day <10 ? '0' + day : day) + ' ' +
    　　  (hour <10 ? '0' + hour : hour) + ':' +
    　　  (min <10 ? '0' + min : min) + ':' +
    　　  (sec <10 ? '0' + sec : sec)
　　  return newTime
    },
    async viewShowData () {
      this.loading = true
      const res = await getApplyList({ pageSize: this.limit, current: this.currentPage,isTrack: 2 })
      this.loading = false
      if (res.code === 0) {
        this.tableData = res.data.newsDataList
        this.count = res.count
      } else if (res.code === 1) {
        this.tableData = []
        this.count = 0
      }
    },
    async adopt (val) {
      var arr = []
      arr.push({sn:val.sn,publishDate:val.publishDate})

      const res = await setTrack({list: arr})
      if(res.code === 0){
        this.$message.success('设置成功')
      }
      this.viewShowData()
    },
    async refuse (val) {
      var arr = []
      arr.push(val.sn)
      const res = await refuseTrack("list="+arr)
      if(res.code === 0){
        this.$message.success('已拒绝该申请')
      }
      this.viewShowData()
    },
    async sizeChange (i) {
      this.currentPage = 1
      this.viewShowData(this.currentPage, i)
    },
    async currentChange (i) {
      this.currentPage = i
      this.viewShowData(i, this.limit)
    }
  },
  mounted () {
    this.viewShowData()
  },
  data () {
    return {
      loading: false,
      tableData: [],
      count: 0,
      limit: 10,
      currentPage: 1,
      form: {
        date1: "",
        date2: "",
        emotionType: "-1",
        isMerge: '1',
        keyWord: "",
        pubDate: "",
        pubDateFrame: [],
        scope: "1",
        sourceType: [1,2,3,4,5,6,7,8,9,10,11],
        tag: -1,
      }
    }
  }
}
</script>

<style lang="scss">
  .audit-list {
    width: 100%;
    min-height: 100%;
    background: #fff;
    box-shadow: 0px 0px 6px 0px rgba(53, 64, 82, 0.06);
    border-radius: 2px;
    padding: 40px 30px;
    position: relative;

    .el-table {
      margin-bottom: 50px;
    }

    .list-bottom {
      display: flex;
      justify-content: space-between;
      position: absolute;
      bottom: 20px;
      left: 20px;
      right: 20px;

      .count {
        line-height: 32px;
      }
    }
  }
</style>
